.root {
  position: relative;
  margin-top: 16px;
}

.svgBorderContainer {
  position: absolute;
}

.svgBorderContainer svg:nth-child(2) {
  position: absolute;
  left: 0;
  top: 0;
}

.svgBorderContainer svg:nth-child(2) rect {
  animation-name: dash;
  animation-timing-function: linear;
  animation-direction: normal;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.qrCodeContainer {
  box-sizing: border-box;
  width: 240px;
  height: 240px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
  border-radius: 30px;
}

.rect {
    animation-name: dash;
    animation-timing-function: linear;
    animation-direction: normal;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.expired svg {
  filter: blur(4px);
}
